import { ToastOptions } from "@ionic/react";
import { HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions";


export class UtilityService {
  static ParseHeadings(text: string): JSX.Element[] {
    const lines = text.split('\n');
    const headings: JSX.Element[] = [];

    for (const line of lines) {
      const colonIndex = line.indexOf(':');
      if (colonIndex !== -1) {
        const header = line.substring(0, colonIndex).trim();
        const description = line.substring(colonIndex + 1).trim();
        headings.push(
          <div className="mb-2" key={header}>
            <h2 className="font-bold">{header}</h2>
            <p>{description}</p>
          </div>
        );
      }
    }

    return headings;
  }
}

export function decodeErrorResponse(data: string) {
  const regex = /\["([^"]+)"\]/g;
  const text = data
  const matches = text.match(regex);
  const result = matches?.map((match: string) => match.slice(2, -2)).join(', ');
  return result
}

export function handleError(err: any, toast: {
  (message: string, duration?: number | undefined): Promise<void>;
  (options: ToastOptions & HookOverlayOptions): Promise<void>;
}) {
  console.log(err)
  toast({ message: err.response?.data.message || "An Error occured. Please try again later!", color: "danger", duration: 4000 })
}