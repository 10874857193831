import React from 'react'
import { Link } from 'react-router-dom'

const Hero = () => {
    return (
        <div className="relative">
            <div className='h-[80vh]  bg-cover ' style={{ backgroundImage: `url(https://images.pexels.com/photos/7683743/pexels-photo-7683743.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)` }}>
                <div className='  bg-black h-full bg-opacity-60  left-0 p-6 px-10 2xl:px-60'>
                    <div className=" md:grid grid-cols-5 absolute top-1/2 -translate-y-1/2">
                        <div className='col-span-3'>
                            <h2 style={{ lineHeight: "50px" }} className="text-4xl lg:text-5xl font-medium text-white">
                                We Power Human Capital <div className='text-orange-500'>Globally  </div></h2>
                            <div className="mt-10">
                                <div className='mb-3'>
                                    <Link to="https://app.opuvia.org/signup">
                                        <button className='bg-orange-500 text-white p-2 px-5 rounded-md w-auto'>
                                            Get Started for Free
                                        </button>
                                    </Link>
                                </div>
                                <div>
                                    <Link to={"/partner-with-us"}>
                                        <button className='bg-white text-orange-500 p-2 px-5 rounded-md w-auto'>
                                            Partner with us
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Hero