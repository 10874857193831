// src/api/axiosInstance.ts
import axios from 'axios';

const DEBUG = false

const axiosInstance = axios.create({
    baseURL: DEBUG ? "http://localhost:5000/api" : "https://backend.opuvia.org/api", // Set this environment variable to your API base URL
});

// Add a request interceptor to include the token in headers if available
axiosInstance.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default axiosInstance;
